<template>
  <el-row type="flex" justify="center" align="middle" class="login-row">
    <el-col :xs="20" :sm="16" :md="12" :lg="6">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>{{ $t("forget_password.title") }}</span>
        </div>
        <el-form class="login-form" :rules="rules" :model="model" ref="form">
          <el-form-item prop="email">
            <el-input
              v-model="model.email"
              :placeholder="$t('forget_password.email')"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item align="right">
            <el-button type="success" block @click="handleForgetPassword"
              >{{ $t("forget_password.submit") }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  metaInfo() {
    return {
      title: "Forget Password - " + this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      model: {
        email: ""
      }
    };
  },
  computed: {
    rules() {
      return {
        email: [
          {
            required: true,
            message: this.$t("register.please_input_email"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("register.please_valid_email"),
            trigger: "blur"
          }
        ]
      };
    }
  },
  watch: {},

  mounted() {
    if (this.$route.query.email) {
      this.model.email = this.$route.query.email;
    }
  },

  methods: {
    ...mapActions("password", ["sendRestLink"]),

    async handleForgetPassword() {
      try {
        let valid = await this.$refs.form.validate();

        if (!valid) {
          return false;
        }
      } catch (e) {
        return e;
      }

      await this.sendRestLink(this.model);

      await this.$message({
        message: this.$t("forget_password.send_mail_success"),
        type: "success"
      });

      await this.$router.push({ name: "Login" });
    }
  }
};
</script>

<style scoped>
.login-row {
  height: 80vh;
}

.el-card /deep/ .el-card__header {
  background: var(--themeColor);
}

.clearfix {
  color: white;
}
</style>
